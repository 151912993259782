import { GetUrlWithPathInput } from 'aws-amplify/storage'
import dayjs from 'dayjs'
import { EsCompetency, EsCompetencyCategory, EsCompetencyMaster, EsMbo, EsMboGoalLevel, EsMboMaster } from './types'
import { fetchAuthSession } from 'aws-amplify/auth'

export default {
  async forceRefreshAuthSession() {
    // セッションを更新
    const session = await fetchAuthSession({ forceRefresh: true })
    console.log('[refreshAuthSession] session', session)
  },

  /**
   * 指定したミリ秒待つ
   * @param ms ミリ秒
   * @returns
   */
  waitMs(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms))
  },

  /**
   * getUrl()のパラメータを取得。URL内のpublic/protectedなどで判断
   * @param keyName S3のキー名
   * @returns
   */
  getUrlOptions(keyName?: string): GetUrlWithPathInput {
    try {
      if (keyName) {
        return {
          path: keyName || '',
          options: {
            expiresIn: 3600,
          },
        }
      } else {
        throw new Error('keyName is required')
      }
    } catch (e: any) {
      console.log('getUrlOptions failed: ', e)
      throw new Error(e)
    }
  },

  /**
   * 評価者ダッシュボードのデフォルトプロフィール画像のキー名を取得
   * @returns
   */
  getReviewerDefaultProfileImageKeyname() {
    return 'public/reviewer-default-thumbnail.jpg'
  },

  /**
   * 被評価者アプリのデフォルトプロフィール画像のキー名を取得
   * @returns
   */
  getRevieweeDefaultProfileImageKeyname() {
    return 'public/reviewee-default-thumbnail.jpg'
  },

  /**
   * 評価者ダッシュボードのプロフィール画像
   * @param keyName キー名
   * @returns
   */
  getReviewerProfileImageUrl(keyName?: string) {
    if (!keyName) {
      keyName = this.getReviewerDefaultProfileImageKeyname()
    }
    return `https://${process.env.NEXT_PUBLIC_S3_REVIEWER_DASHBOARD_BUCKET_NAME}.s3.amazonaws.com/${keyName}`
  },

  /**
   * 被評価者アプリのプロフィール画像
   * @param keyName キー名
   * @returns
   */
  getRevieweeProfileImageUrl(keyName?: string) {
    if (!keyName) {
      keyName = this.getRevieweeDefaultProfileImageKeyname()
    }
    return `https://${process.env.NEXT_PUBLIC_S3_REVIEWEE_APP_BUCKET_NAME}.s3.amazonaws.com/${keyName}`
  },

  /**
   * 16進数文字列を指定の桁数生成
   * @param length 桁数
   */
  getRandomString(length: number) {
    return Array.from({ length }, () => Math.floor(Math.random() * 16).toString(16)).join('')
  },

  /**
   * 年月のフォーマットを取得
   * @param dateString 日付文字列
   * @returns
   */
  getYearMonth(dateString: string) {
    return dayjs(dateString).format('YYYY年M月')
  },

  /**
   * 日付のフォーマットを取得
   * @param dateString 日付文字列
   * @returns
   */
  getDateString(dateString: string) {
    return dayjs(dateString).format('YYYY年M月D日')
  },

  /**
   * 年なしの日付のフォーマットを取得
   * @param dateString 日付文字列
   * @returns
   */
  getNonYearDateString(dateString: string) {
    return dayjs(dateString).format('M月D日')
  },

  /**
   * 年月日時のフォーマットを取得
   * @param dateString 日付文字列
   * @returns
   */
  getFullDatetimeString(datetimeString: string) {
    return dayjs(datetimeString).format('YYYY年M月D日 HH:mm')
  },

  /**
   * ステータスのセレクタのアイテムを取得
   * @param statusType ステータスタイプ
   * @param statusStringType ステータス文字列タイプ
   * @returns
   */
  getStatusSelectorItems(statusType: any, statusStringType: any) {
    const items = []
    for (const key of Object.keys(statusType) as (keyof typeof statusType)[]) {
      // console.log(key, statusType[key], statusStringType[statusType[key]])
      items.push({ label: statusStringType[statusType[key]], value: statusType[key] })
    }
    return items
  },

  /**
   * 指定したステータスがしきい値となるステータスよりあとのステータスか先のステータスかを調べる
   * @param targetStatusType ステータスタイプ
   * @param status チェックしたいステータス
   * @param thresholdStatus しきい値ステータス。しきい値ステータス以降がafterStatusとなる
   * @param isBefore しきい値ステータス以降がbeforeStatusであるかどうか
   */
  checkStatusSequence: (targetStatusType: any, status: any, thresholdStatus: any, isBefore = true) => {
    const beforeStatus = []
    const afterStatus = []
    let foundFlag = false
    for (const key of Object.keys(targetStatusType)) {
      const targetStatus = targetStatusType[key as keyof typeof targetStatusType]
      // console.log(key, targetStatus)
      if (foundFlag) {
        afterStatus.push(targetStatus)
      } else {
        if (thresholdStatus === targetStatus) {
          foundFlag = true
          afterStatus.push(targetStatus)
        } else {
          beforeStatus.push(targetStatus)
        }
      }
    }
    if (isBefore) {
      return beforeStatus.includes(status)
    } else {
      return afterStatus.includes(status)
    }
  },

  /**
   * 数値を文字列に変換
   * @param number 数値
   * @param unit 単位
   * @returns 文字列
   */
  getNumberString(number: string | number | null | undefined, unit: string | undefined | null = undefined) {
    const unitString = typeof unit === 'string' ? `${unit}` : ''
    if (number === null || number === undefined) {
      return '-' + unitString
    } else {
      return Number(number).toLocaleString() + unitString
    }
  },

  /**
   * 達成度を文字列として取得
   * @param result 結果
   * @param goal 目標
   * @returns
   */
  getAcheivementRate(result: string | number | undefined | null, goal: string | number | undefined | null) {
    if ((typeof result === 'number' || typeof result === 'string') && (typeof goal === 'number' || typeof goal === 'string')) {
      return Math.ceil((Number(result) / Number(goal)) * 100) + ' %'
    } else {
      return '-'
    }
  },

  /**
   * メソッドの文字列を取得
   * @param method メソッド
   * @returns
   */
  getMethodString(method: 'create' | 'update' | 'delete') {
    if (method === 'create') {
      return '作成'
    } else if (method === 'update') {
      return '更新'
    } else if (method === 'delete') {
      return '削除'
    }
  },

  /**
   * ボタンやタグのカラーを取得
   * @param order スキルタイプの順番
   * @returns
   */
  getAntColor(order: number) {
    if (order === 1) {
      return 'processing'
    } else if (order === 2) {
      return 'magenta'
    } else if (order === 3) {
      return 'green'
    } else if (order === 4) {
      return 'volcano'
    } else if (order === 5) {
      return 'orange'
    } else if (order === 6) {
      return 'volcano'
    } else if (order === 7) {
      return 'gold'
    } else if (order === 8) {
      return 'lime'
    } else if (order === 9) {
      return 'geekblue'
    } else {
      return 'cyan'
    }
  },

  /**
   * MBOのタイトルを取得。
   * MBOのタイトルがあればそれを、なければMBOマスターのタイトルを取得する。
   * @param esMbo MBO
   * @returns
   */
  getEsMboTitle(esMbo: EsMbo) {
    return esMbo.title || esMbo.esMboMaster?.title || '-'
  },

  /**
   * 評価コンピテンシーのタイトルを取得
   * @param esCompetency 評価コンピテンシー
   * @returns
   */
  getEsCompetencyTitle(esCompetency: EsCompetency) {
    return esCompetency.title || esCompetency.esCompetencyMaster?.title || '-'
  },

  /**
   * 評価コンピテンシーの説明を取得
   * @param esCompetency 評価コンピテンシー
   * @returns
   */
  getEsCompetencyDescription(esCompetency: EsCompetency) {
    return esCompetency.description || esCompetency.esCompetencyMaster?.description || '-'
  },

  /**
   * 評価コンピテンシーマスターのタイトルを取得
   * @param esCompetencyMaster 評価コンピテンシーマスター
   * @returns
   */
  getEsCompetencyMasterTitle(esCompetencyMaster: EsCompetencyMaster) {
    return esCompetencyMaster.title || esCompetencyMaster.competency?.title || esCompetencyMaster.competency?.competencyTemplate?.title || '-'
  },

  /**
   * 評価コンピテンシーマスターの説明を取得
   * @param esCompetencyMaster 評価コンピテンシーマスター
   * @returns
   */
  getEsCompetencyMasterDescription(esCompetencyMaster: EsCompetencyMaster) {
    return (
      esCompetencyMaster.description ||
      esCompetencyMaster.competency?.description ||
      esCompetencyMaster.competency?.competencyTemplate?.description ||
      '-'
    )
  },

  /**
   * 評価MBOの目標レベルの最小値をマスターやマスターテンプレートの値から取得
   * @param esMboGoalLevel 評価MBOの目標レベル
   * @returns
   */
  getEsMboGoalLevelMin(esMboGoalLevel: EsMboGoalLevel) {
    if (typeof esMboGoalLevel.goalMin === 'number') {
      return esMboGoalLevel.goalMin
    } else if (typeof esMboGoalLevel.esMboGoalLevelMaster?.goalMin === 'number') {
      return esMboGoalLevel.esMboGoalLevelMaster?.goalMin
    } else if (typeof esMboGoalLevel.esMboGoalLevelMaster?.esMboGoalLevelMasterTemplate?.goalMin === 'number') {
      return esMboGoalLevel.esMboGoalLevelMaster?.esMboGoalLevelMasterTemplate?.goalMin
    } else {
      return '-'
    }
  },

  /**
   * 評価MBOの目標レベルの最大値をマスターやマスターテンプレートの値から取得
   * @param esMboGoalLevel 評価MBOの目標レベル
   * @returns
   */
  getEsMboGoalLevelMax(esMboGoalLevel: EsMboGoalLevel) {
    if (typeof esMboGoalLevel.goalMax === 'number') {
      return esMboGoalLevel.goalMax
    } else if (typeof esMboGoalLevel.esMboGoalLevelMaster?.goalMax === 'number') {
      return esMboGoalLevel.esMboGoalLevelMaster?.goalMax
    } else if (typeof esMboGoalLevel.esMboGoalLevelMaster?.esMboGoalLevelMasterTemplate?.goalMax === 'number') {
      return esMboGoalLevel.esMboGoalLevelMaster?.esMboGoalLevelMasterTemplate?.goalMax
    } else {
      return '-'
    }
  },

  /**
   * コンピテンシーカテゴリーの名称を取得
   * @param esCompetencyCategory コンピテンシーカテゴリー
   * @returns
   */
  getEsCompetencyCategoryName(esCompetencyCategory: EsCompetencyCategory | null | undefined) {
    if (esCompetencyCategory) {
      return esCompetencyCategory.name || esCompetencyCategory.esCompetencyCategoryTemplate?.name || '-'
    } else {
      return '-'
    }
  },

  getEsMboGoalLevelValueMin(esMboMaster: EsMboMaster | undefined, level: number) {
    if (esMboMaster) {
      const esMboGoalLevelMaster = esMboMaster?.esMboGoalLevelMasters?.find(
        (esMboGoalLevelMaster) => esMboGoalLevelMaster.level === level || esMboGoalLevelMaster.esMboGoalLevelMasterTemplate?.level === level
      )
      if (esMboGoalLevelMaster) {
        if (typeof esMboGoalLevelMaster.goalMin === 'number') {
          return esMboGoalLevelMaster.goalMin
        } else {
          const esMboGoalLevelMasterTemplate = esMboGoalLevelMaster.esMboGoalLevelMasterTemplate
          if (typeof esMboGoalLevelMasterTemplate.goalMin === 'number') {
            return esMboGoalLevelMasterTemplate.goalMin
          }
        }
      }
    }

    return null
  },

  getEsMboGoalLevelValueMax(esMboMaster: EsMboMaster | undefined, level: number) {
    if (esMboMaster) {
      const esMboGoalLevelMaster = esMboMaster?.esMboGoalLevelMasters?.find(
        (esMboGoalLevelMaster) => esMboGoalLevelMaster.level === level || esMboGoalLevelMaster.esMboGoalLevelMasterTemplate?.level === level
      )
      if (esMboGoalLevelMaster) {
        if (typeof esMboGoalLevelMaster.goalMax === 'number') {
          return esMboGoalLevelMaster.goalMax
        } else {
          const esMboGoalLevelMasterTemplate = esMboGoalLevelMaster.esMboGoalLevelMasterTemplate
          if (typeof esMboGoalLevelMasterTemplate.goalMax === 'number') {
            return esMboGoalLevelMasterTemplate.goalMax
          }
        }
      }
    }

    return null
  },
}
