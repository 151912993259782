import { del, get, post, put } from 'aws-amplify/api'
import { fetchAuthSession } from 'aws-amplify/auth'

export default {
  /**
   * GETメソッド
   * @param path パス
   * @returns
   */
  async get<T>(path: string): Promise<T> {
    try {
      const authToken = (await fetchAuthSession()).tokens?.idToken?.toString() || ''
      const restOperation = get({
        apiName: 'ReviewerDashboardExpress',
        path: `/v1${path}`,
        options: {
          headers: {
            Authorization: authToken,
          },
        },
      })
      const { body } = await restOperation.response
      const response = await body.json()

      if (response && typeof response === 'object' && 'result' in response && response.result === 'OK') {
        return response.data as T
      } else {
        if (response && typeof response === 'object' && 'error' in response) {
          console.log('GET call request failed: ', response.detail)
          throw new Error(response.error?.toString())
        } else {
          throw new Error('予期せぬエラーが発生しました')
        }
      }
    } catch (e: any) {
      console.log('GET call failed: ', e)
      throw new Error(e)
    }
  },

  /**
   * POSTメソッド
   * @param path パス
   * @param params POSTパラメータ
   * @returns
   */
  async post<T>(path: string, params: any): Promise<T> {
    try {
      const authToken = (await fetchAuthSession()).tokens?.idToken?.toString() || ''
      const restOperation = post({
        apiName: 'ReviewerDashboardExpress',
        path: `/v1${path}`,
        options: {
          headers: {
            Authorization: authToken,
          },
          body: params,
        },
      })
      const { body } = await restOperation.response
      const response = await body.json()

      if (response && typeof response === 'object' && 'result' in response && response.result === 'OK') {
        return response.data as T
      } else {
        if (response && typeof response === 'object' && 'error' in response) {
          console.log('POST call request failed: ', response.detail)
          throw new Error(response.error?.toString())
        } else {
          throw new Error('予期せぬエラーが発生しました')
        }
      }
    } catch (e: any) {
      console.log('POST call failed: ', e)
      throw new Error(e)
    }
  },

  /**
   * PUTメソッド
   * @param path パス
   * @param params PUTパラメータ
   * @returns
   */
  async put<T>(path: string, params: any): Promise<T> {
    try {
      const authToken = (await fetchAuthSession()).tokens?.idToken?.toString() || ''
      const restOperation = put({
        apiName: 'ReviewerDashboardExpress',
        path: `/v1${path}`,
        options: {
          headers: {
            Authorization: authToken,
          },
          body: params,
        },
      })
      const { body } = await restOperation.response
      const response = await body.json()

      if (response && typeof response === 'object' && 'result' in response && response.result === 'OK') {
        return response.data as T
      } else {
        if (response && typeof response === 'object' && 'error' in response) {
          console.log('PUT call request failed: ', response.detail)
          throw new Error(response.error?.toString())
        } else {
          throw new Error('予期せぬエラーが発生しました')
        }
      }
    } catch (e: any) {
      console.log('PUT call failed: ', e)
      throw new Error(e)
    }
  },

  /**
   * DELETEメソッド
   * @param path パス
   * @param params DELETEパラメータ
   * @returns
   */
  async del<T>(path: string, params: any): Promise<any> {
    try {
      const authToken = (await fetchAuthSession()).tokens?.idToken?.toString() || ''
      const restOperation = del({
        apiName: 'ReviewerDashboardExpress',
        path: `/v1${path}`,
        options: {
          headers: {
            Authorization: authToken,
          },
        },
      })
      // @ts-ignore
      const { body } = await restOperation.response
      const response = await body.json()

      if (response && typeof response === 'object' && 'result' in response && response.result === 'OK') {
        return response.data as T
      } else {
        if (response && typeof response === 'object' && 'error' in response) {
          console.log('DELETE call request failed: ', response)
          throw new Error(response.error?.toString())
        } else {
          throw new Error('予期せぬエラーが発生しました')
        }
      }
    } catch (e: any) {
      console.log('DELETE call failed: ', e)
      throw new Error(e)
    }
  },
}
