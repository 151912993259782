import { Layout, Spin } from 'antd'
import styles from './LoadingOverlay.module.css'

const { Content } = Layout

interface LoadingOverlayProps {
  show: boolean
  message?: string
}

export default function LoadingOverlay({ show, message }: LoadingOverlayProps) {
  return (
    show && (
      <Layout className={styles.layoutContainer}>
        <Content className={styles.loadingContainer}>
          <Spin tip={message || 'Loading...'} fullscreen>
            <div className={styles.loadingSpin} />
          </Spin>
        </Content>
      </Layout>
    )
  )
}
